"use client";

import { metadata, type AITool } from "@app/_metadata";
import { ActiveFeatureFlags } from "@shared-components/global/active-feature-flags";
import { CoreMetricsSheet } from "@shared-components/core-metrics-sheet";
import { Ident } from "@shared-components/global/ident";
import { TopBarMenu } from "@shared-components/global/top-bar-menu";
import { UserDropdown } from "@shared-components/global/user-dropdown";

import Nisse from "@shared-assets/nisse.png";
import Link from "next/link";
import { usePathname } from "next/navigation";

import Image from "next/image";

interface TopBarProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  aiTools: AITool[];
}

/**
 * Topbar
 *
 * @param props TopBarProps
 */
export const TopBar = (props: TopBarProps) => {
  const path = usePathname();
  // Find active tool
  const activeAiTool = metadata.aiTools?.find((appMeta) =>
    path.includes(appMeta.slug),
  );
  /**
   * Render
   */
  return (
    <>
      <div className="fixed left-0 top-0 z-50 flex h-14 w-full flex-row items-center justify-between border-b border-border bg-background px-4">
        <div className="flex flex-row items-center">
          <Link href={"/"}>
            <Ident aiTool={activeAiTool} />
          </Link>
          <span className="hidden md:block">{props.leftChildren}</span>
          <div>
            <Image
              width={70}
              className="pointer-events-none relative top-[39px] hidden scale-x-[-1] md:block "
              src={Nisse}
              alt="nisse"
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <ActiveFeatureFlags />
          <span className="hidden md:block">{props.rightChildren}</span>
          <CoreMetricsSheet />
          <UserDropdown />
          <TopBarMenu activeAiTool={activeAiTool!} />
        </div>
      </div>
    </>
  );
};
